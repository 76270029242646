<template>
  <div>
    <div class="slider-header" :dir="localLang === 'ara' ? 'rtl' : 'ltr'">
      <div class="slider-title">
        <span class="title">{{$t(contents.title)}}</span>
      </div>
      <div class="slider-head">
        <div class="slider-head-btns">
          <div
            class="slider-controls"
            :id="`${contents.title}-search-slide-controls`"
            :dir="localLang === 'ara' ? 'ltr' : ''"
          >
            <!-- previous-arrow -->
            <button type="button">
              <img src="@/assets/icons/less-than-filled-50.png" width="30px" alt="less" />
            </button>
            <!-- next-arrow -->
            <button type="button">
              <img src="@/assets/icons/more-than-filled-50.png" width="30px" alt="more" />
            </button>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <div
      :id="`${contents.title}-category-search-contents`"
      :dir="localLang === 'ara' ? 'rtl' : 'ltr'"
    >
      <div
        v-for="(item, index) in contents.objects"
        :key="index"
        class="categorised-search-contents"
      >
        <div class="search-contents-box">
          <categoryCard :content="item" :cardType="cardDisplayType"></categoryCard>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { tns } from "tiny-slider/src/tiny-slider";
export default {
  props: {
    contents: {
      type: Object
    },
    localLang: {
      type: String
    }
  },
  data() {
    return {
      cardDisplayType: null,
      localDisplayLang: null
    };
  },
  created() {
    // console.log("contents in search carousel", this.contents);

    this.fetchCardType();
    //event for card size check for every screen sizes
    eventBus.$on("re-render-carousel", () => {
      this.fetchCardType();
    });

    // eventBus.$on("check-local-storage", data => {
    //   this.localDisplayLang = data;
    //   // console.log("localDisplayLang in search carousel", this.localDisplayLang);
    // });
  },
  mounted() {
    this.constructCarousel();
  },
  methods: {
    fetchCardType() {
      if (screen.width <= 375) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "90px",
          width: "160px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 375 && screen.width <= 425) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "100px",
          width: "170px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 425 && screen.width <= 500) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "110px",
          width: "180px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 490 && screen.width <= 590) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "130px",
          width: "200px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 590 && screen.width <= 690) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "115px",
          width: "185px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 690 && screen.width <= 800) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "145px",
          width: "215px",
          playButton: { enablePlay: false }
        };
      } else if (screen.width > 800 && screen.width < 1050) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "120px",
          width: "210px",
          playButton: { enablePlay: false }
        };
      } else {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "155px",
          width: "260px",
          playButton: { enablePlay: false }
        };
      }
    },
    constructCarousel() {
      let data = {
        container: `#${this.contents.title}-category-search-contents`,
        controlsContainer: `#${this.contents.title}-search-slide-controls`,
        nav: false,
        loop: false,
        slideBy: "page",
        gutter: 5,
        responsive: {
          320: {
            items: 2,
            edgePadding: 5,
            gutter: 5
          },
          576: {
            items: 3,
            edgePadding: 5,
            gutter: 5
          },
          900: {
            edgePadding: 5,
            gutter: 7,
            items: 4
          }
        }
      };
      this.$nextTick(() => {
        this.slider = tns(data);
      });
    }
  },

  components: {
    categoryCard: () =>
      import(
        /* webpackChunkName: "categorycard" */ "@/components/Templates/categoryCard.vue"
      )
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "node_modules/tiny-slider/src/tiny-slider.scss";
.container {
  .slider-header {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    .slider-title {
      display: flex;
      .title {
        color: $clr-light-gd5;
        font-family: $font-regular;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.15px;
        line-height: 24px;
      }
    }
    .slider-head {
      // margin-bottom: 5px;
      display: grid;
      justify-content: end;
      .slider-head-btns {
        .slider-controls {
          button {
            border: none;
            background: transparent;
            cursor: pointer;
            img {
              height: 20px;
              width: 20px;
            }
            &:disabled {
              opacity: 0.3;
            }
            &:focus {
              outline: none;
            }
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
</style>